import React, {   Component } from "react";


const Url2 = global.configappurl;
class Home extends Component {
  
  constructor()
  {
    super();
    this.state={
      data: [],
    }
  }
  componentDidMount()
  {
    
    fetch( global.configappurl
      ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "Content-Type": "text/plain"
         }
      }
      )
        .then(function(response){
         
          return response.json();
        })
        .then(response => this.setState({ data:response }));
  }
  render() {
    
    return (
      <div>
       <div>
         
          <div dangerouslySetInnerHTML={ {__html: this.state.data.body} } />
        </div> 
      </div>
    );
  }
}
 
export default Home;