 
import React from 'react';
import { useTranslation } from 'react-i18next';
const slang = localStorage.getItem("language");
//alert(slang);
if(slang =='en'){
    global.configappurl = "https://bip-react.trillionhk.com/apicode/en/?route=api/api";
}
else{
    global.configappurl = "https://bip-react.trillionhk.com/apicode/?route=api/api";
}
