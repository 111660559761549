import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {removeItem,increaseQty,decreaseQty} from './Common.js';
class Cart extends Component{

  
    
    render(){
        const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
        const arrayproduct =  JSON.parse(oldproduct); 
        const totalcartitem =arrayproduct.length;
        console.log(arrayproduct);
        var totalprice=0;
       // alert(totalcartitem)
         for (var i = 0; i < totalcartitem; i++) {
             if(arrayproduct[i].qty){
                totalprice +=arrayproduct[i].qty*arrayproduct[i].itemprice;
             }
           
           //alert(arrayproduct[i].thumb)
          
          
         }

       return( 
        
        <div class="mini-cart-info">
            <table>
        <tbody>
        {
        arrayproduct.map((cartitem, index) => (
            
         <tr class="product_row not_gift cart-row" id={cartitem.itemid} product_id={cartitem.itemid} parent_product_id="" product_row="0">
        <td class="image">
        <a href=""><img src={cartitem.itemthumb} alt={cartitem.itemname} title={cartitem.name} /></a>
        </td>
        <td class="name">
        <a href="">{cartitem.itemname} <br></br>{cartitem.pricestring} x {cartitem.qty}</a>
      
     </td>
   <td class="quantity">
   <div class="quantity_wrap">
   <div class="quantity_wrap"><span class="stepper down" onClick={()=> { decreaseQty(cartitem.itemid) } }>-</span>
   <input type="text" name="order_product[0][quantity]" value={cartitem.qty} class="quantity" min="1" readOnly /><span class="stepper up" onClick={()=> { increaseQty(cartitem.itemid) } }>+</span>
   </div>
   <a class="btn_remove" onClick={()=> {if (window.confirm('Are you sure to remove this item from cart?')) removeItem(cartitem.itemid) } } ></a>
   
   </div>
   </td>
         </tr>
      
        ))
         }
   </tbody>
   </table>

     
     <div class="mini-cart-total" price={totalprice}>
         <table><tbody><tr>
       <td><b>Sub-Total:</b></td><td>{totalprice}</td></tr><tr><td><b>Total:</b></td><td>{totalprice}</td>
       </tr></tbody></table>
      
     </div>
     
      

   
   </div>
   
       )
    }
}


export default (Cart);

