
import logo from './logo.svg';
import './App.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {removeItem} from './Common.js';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
function Book() {

  const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
  const arrayproduct =  JSON.parse(oldproduct); 
  const totalcartitem =arrayproduct.length;
 var totalprice=0;
  for (var i = 0; i < totalcartitem; i++) {
    totalprice +=arrayproduct[i].qty*arrayproduct[i].itemprice;
    //alert(arrayproduct[i].thumb)
   
   
  }

  var carttotal=`<table><tbody><tr><td><b>Sub-Total:</b></td><td>$ ${totalprice}</td></tr>`;
  carttotal +=`<tr><td><b>Total:</b></td><td>$ ${totalprice}</td></tr>`;
  
  carttotal +=`</tbody></table>`;
  const {t} = useTranslation();
  function removeItemFromBasket(){

  }
  return (
    <div id="cart"><Link to={"/cart"} class="heading"> <div class="cart-icon"></div>
    <span class="cart-total"><span id="cart-total">{totalcartitem}</span></span></Link>
    <div class="content"><div id="cart-content"><div class="mini-cart-info" id="mini-cart">
     <table><tbody>
      {
      arrayproduct.map((cartitem, index) => (
       <tr class="product_row not_gift cart-row" id={cartitem.itemid} product_id={cartitem.itemid} parent_product_id="" product_row="0">
      <td class="image">
      <a href=""><img src={cartitem.itemthumb} alt={cartitem.itemname} title={cartitem.name} /></a>
      </td>
      <td class="name">
      <a href="">{cartitem.itemname} <br></br>{cartitem.pricestring} x {cartitem.qty}</a>
    
   </td>
 <td class="quantity">
 <div class="quantity_wrap">
 <div class="quantity_wrap"><span class="stepper down">-</span>
 <input type="text" name="order_product[0][quantity]" value={cartitem.qty} class="quantity" min="1" /><span class="stepper up">+</span>
 </div>
 <a class="btn_remove" onClick={()=> {if (window.confirm('Are you sure to remove this item from cart?')) removeItem(cartitem.itemid) } } ></a>
 
 </div>
 </td>
       </tr>
    
      ))
       }
 </tbody></table>
      </div>
      <div class="mini-cart-total"><div dangerouslySetInnerHTML={ {__html: carttotal} } /></div>
      <div class="checkout"><a href="/index.php?route=checkout/checkout" class="button">Checkout</a></div>
      </div>
      </div>
    </div>
  );
}

export default Book;