import { param } from "jquery";
import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import * as $ from "jquery";
import { Link } from 'react-router-dom';   
import axios from 'axios';
import {removeItem,increaseQty,decreaseQty} from './Common.js';
import Book from './Book';
import Cart from './Cart';
//import { connect } from 'react-redux';
import {  connect } from 'react-redux';
//import { addToCart } from './cartactions/cartActions';
//import { addToCartd } from './actions/cartActions';
import ReactDOM from 'react-dom';
import FlashMessage from 'react-flash-message';
const message = () => {
  alert('f');
 }
  class Category extends Component { 
    constructor(props) {
        super(props);
        this.state={
            data: [],
            loading: true,
            cartitem: 0,
            showMessage: false,
            category: this.props.match.params.id
          }
          this.getCategory = this.getCategory.bind(this);
          this.handleClick = this.handleClick.bind(this);
    }
    
    async componentDidMount() {
        
       
        this.fetchData(); 
      }
    
      getCategory(event) {
        this.setState({ category: event.target.value }, function(){  } );
        this.fetchData();
      }
 
      fetchData() {
        //console.log('yes');
        fetch(global.configappurl+"/products/"+this.state.category)
          .then(response => response.json())
          .then(response => {
            console.log(response)
            this.setState({
              data: response
            })
    
          })
        
      }
     message() {
      console.log('y'); 
     }
      handleClick(event) {
        const id = event.target.id;
        const name = event.target.title;
        const price =event.target.getAttribute('itemprice'); 
        const thumb = event.target.getAttribute('thumb');
        const pricenumber = Number(price.replace(/[^0-9.-]+/g,""));
       // const cartObj=[];
      console.log(event.target); 
        const cartObj = {
          itemid: id,
          pricestring:price,
          itemprice:pricenumber,
          itemname:name,
          itemthumb:thumb,
          qty: 1
      };
      var value = id;
      var found = false;
      const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
      const arrayproduct =  JSON.parse(oldproduct);  
     if(arrayproduct.length){
       $('#cart-total').text(arrayproduct.length);
     }
     var totalprice=0; var subtotal=0;
     var carthtml='<table><tbody>';
      for (var i = 0; i < arrayproduct.length; i++) {
        if (arrayproduct[i].itemid === id) {
          arrayproduct[i] = cartObj;
          found = true;
          arrayproduct[i].qty=arrayproduct[i].qty+1;
        } 
        totalprice +=arrayproduct[i].qty*arrayproduct[i].itemprice;
        //alert(arrayproduct[i].thumb)
        carthtml += `<tr class="product_row not_gift"  product_id='${arrayproduct[i].itemid}' parent_product_id="" product_row="0">
        <td class="image">
        <a href=""><img src="${arrayproduct[i].itemthumb}" alt="${arrayproduct[i].itemname}" title="${arrayproduct[i].name}"></a>
        </td>
        <td class="name"><a href="">${arrayproduct[i].itemname}<br>${arrayproduct[i].pricestring} x ${arrayproduct[i].qty}</a></td>
        <td class="quantity"><div class="quantity_wrap"><span class="stepper down">-</span><input type="text" name="order_product[0][quantity]" value="${arrayproduct[i].qty}" class="quantity" min="1"><span class="stepper up">+</span></div>
        <a class="btn_remove" onclick="${this.lhandleClick}" /></a>
        
        </td>
        </tr>`;
       
      }
      carthtml +='</tbody></table>';
     
      var carttotal=`<table><tbody><tr><td><b>Sub-Total:</b></td><td>$ ${totalprice}</td></tr>`;
      carttotal +=`<tr><td><b>Total:</b></td><td>$ ${totalprice}</td></tr>`;
      
      carttotal +=`</tbody></table>`;
      if (!found) {
        arrayproduct.push(cartObj);
      }
      this.setState({ showMessage: true });
      //Make div hide containing flash message
      setTimeout(
        () => this.setState({ showMessage: false }),
        5000
      );
 
       
    
       
     
     
      if(!oldproduct){
        localStorage.setItem("cart", JSON.stringify(cartObj));
        $('#cart-total').text(cartObj.length);
       }
       else{
        localStorage.setItem("cart", JSON.stringify(arrayproduct));
        $('#cart-total').text(arrayproduct.length);
       }
       ReactDOM.render(<Cart/>, document.getElementById('cart-content'));
      //localStorage.clear();
// getting back the object
    const copyCartObj = JSON.parse(localStorage.getItem("cart"));
     console.log(copyCartObj);
       // addToCart(id,1); 
      }
      
    handleEntailmentRequest(e){
      e.preventDefault();
      console.log('fp');
  
  }
  
    render() {
       console.log(this.state.data.products);
      
    var products = [];
   
    $.each(this.state.data.products , function(index, val) { 
    products.push({
    name: val.name,
    id: val.product_id,
    price: val.price,
    thumb:val.thumb,
    rating:val.rating,
    text_number_sold:val.text_number_sold,
    reviews:val.reviews,
    href:val.href
       });
  
       });
     
      return (
      
        <div class="catproduct">
          <div id="notification">
            { this.state.showMessage &&  
          <div className="flashdiv">
           <FlashMessage duration={5000}>
            <strong>Success: You have added </strong>
           </FlashMessage>
         </div>
         }
        
         
         </div>
          <h2>{this.props.match.params.id}</h2>
         <div class="product-list">
          {

          products.map((product, index) => (
            <div class="product-grid">
            <a class="wrap" href=""></a>
            <div class="top">
                <div class="image">
                    <a href={product.href}><img src={product.thumb} alt={product.name}  title={product.name} /></a>
                    <span class="product_grid_1"></span>
                    <span class="product_grid_2"></span>
                </div>
             
                  <div data-countdown="" class="countdown"></div>
            
                <span class="product_grid_3"></span>
                <div class="name">
                    <a href="<?php echo $product['href']; ?>">{product.name}</a>
                </div>
                <span class="product_grid_4"></span>
            </div>
            <div class="bottom">
             
                    <div class="rating">
                      <span class="star-rating">
                       <span class="back-stars"><i></i><i></i><i></i><i></i><i></i></span>
                        <span class="front-stars"><i></i><i></i><i></i><i></i><i></i></span>
                      </span>
                      <span class="rating-qty">({product.reviews})</span>
                    </div>
           
            
                    <div class="price">
                
                        <span class="price-old">{product.price}</span>
                     
                    </div>
             
                <div class="product_number_sold">{product.text_number_sold}</div>
                <a id={product.id} data-tag={product.id} title={product.name} itemprice={product.price} thumb={product.thumb} onClick={this.handleClick} class="button">Add to cart</a>
                 
            </div>
        </div>
           
        ))
         }
         </div>
        </div>
      );
    }
  }
 

export default Category;
//export default connect(mapStateToProps,mapDispatchToProps)(Category)