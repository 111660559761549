
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Config';
import './i18n';
import './Lang';
import { I18nextProvider } from "react-i18next";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
const apiurl="https://bip-react.trillionhk.com/en/?route=api/api";
ReactDOM.render(
  <BrowserRouter>
  <App />
</BrowserRouter>,
document.querySelector('#root')
);
ReactDOM.render(
  <I18nextProvider >
  <App />
  </I18nextProvider>,
  document.getElementById("root")
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
