import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import * as $ from "jquery";
import {removeItem,increaseQty,decreaseQty} from './Common.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import Fridges_Wine_Cabinet from './Fridges_Wine_Cabinet';
import Home from './Home';
import Category from './Category';
import Book from './Book';
import Cart from './Cart';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import config from './Config';

//import i18n from 'i18next';
//const Url = "https://api.github.com/users/deekshasharma";

const Url2 = global.configappurl;

function App() {
  
  const [data,setData]=useState([]);
  const getData=()=>{
    fetch( global.configappurl
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Content-Type": "text/plain"
       }
    }
    )
      .then(function(response){
    
        return response.json();
      })
      .then(function(myJson) {
        
        setData(myJson)
      });
  }
  useEffect(()=>{
    getData()
  },[])
 
    var html=5;
    var links = data.menu;
   
  //console.log(links.length);
  var i ;
  var loopData = '';
  //for(i=0; i < links.length; i++){
   // loopData += `<li>${links[i].name}</li>`;
//}
var menuname = [];
$.each(links , function(index, val) { 
  menuname.push({
    name: val.name,
    href: val.href,
    subcat: val.sub_categories
});
  loopData += `<li class="${val.css_class}"> <a href="${val.href}">${val.name}</a></li>`;
});
//const {t} = this.props;
//const {t} = useTranslation();
//const { t } = useTranslation();


  function lhandleClick(lang) {
    i18next.changeLanguage(lang);
    localStorage.setItem("language", lang);
    if('caches' in window){
      caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach(name => {
                  caches.delete(name);
              })
          });
  
          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true);
      }
    //window.location.reload(true); 
   // const copyCartObj = JSON.parse(localStorage.getItem("cart"));
    console.log(slang);
  }
 function removeItemFromBasket(){

 }
  const slang = localStorage.getItem("language");
  const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
  const arrayproduct =  JSON.parse(oldproduct); 
  const totalcartitem =arrayproduct.length;
 var totalprice=0;
  for (var i = 0; i < totalcartitem; i++) {
    totalprice +=arrayproduct[i].qty*arrayproduct[i].itemprice;
    //alert(arrayproduct[i].thumb)
   
   
  }

  var carttotal=`<table><tbody><tr><td><b>Sub-Total:</b></td><td>$ ${totalprice}</td></tr>`;
  carttotal +=`<tr><td><b>Total:</b></td><td>$ ${totalprice}</td></tr>`;
  
  carttotal +=`</tbody></table>`;

  return (
    <div className="App">
    <div class="main-content"><div class="header">
      
    <Router>
    <header>
      <div id="topbar"><div class="container">
      <div>   
     
    
 <div class="top-bar-left">
<a href="">N.P 2812 9069</a>
<a href="">M.K 2384 9277</a>
<a href="">Whatsapp 6761 1327</a>

</div>
<div class="top-bar-right">
<a href="/support">FAQ</a>
<a href="/order-process">Order Process</a>
<a href="/posts">Information Center</a>
<a href="/contact-us">Contact Us</a>
<a href="/join-us">Join Us</a>

{slang=='en' ? (
     
     <button onClick={()=>lhandleClick('zh')} >
     中文
  </button>
      ) : (
       <button onClick={()=>lhandleClick('en')} >
      English{slang}
      </button>
      )}
  
        
        
</div>
</div>
</div>
</div>

<div id="header">
    <div class="container">
      <div class="left">
        <div id="logo">
     
          <Link to="/"><img src="https://bip-react.trillionhk.com/apicode/catalog/view/theme/bip/image/logo.svg" title="BUILT-IN PRO" alt="BUILT-IN PRO"/></Link>
          </div>
          </div>
      <div class="center"><div id="search"><input type="text" name="search" placeholder="Search" value=""/><div class="button-search"></div></div></div>
      <div class="right">
      <div id="cart">
        <Link to={"/cart"} class="heading"> <div class="cart-icon"></div>
    <span class="cart-total"><span id="cart-total">{totalcartitem}</span></span></Link>
    <div class="content">
      <div id="cart-content">
      <Cart />    
      </div>
      </div>
       
     </div>
    </div>
    </div>
  </div>
   <div id="menu">
      <ul class="container">
       
    {
       menuname.map((menu, index) => (
        <li><Link to={ menu.href}>{menu.name}</Link></li>
       
    ))
    }
   
       </ul>
       </div>
 </header>

<div dangerouslySetInnerHTML={ {__html: data.headerbottom} } />
<Switch>
         
<Route exact path="/" component={Home}></Route>

<Route
   path="/:id" render={(props) => (
    <Category key={props.match.params.id} {...props} />)
  } 
></Route>
<Route exact path="/book" component={Book}></Route>  
<Route exact path="/cart" component={Cart}></Route>  
 </Switch>
</Router>

   </div>
   
    <div dangerouslySetInnerHTML={ {__html: data.footer} } />
    </div>
     
    </div>
  );
}

export default App;
