import { param } from "jquery";
import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import * as $ from "jquery";
import { Link } from 'react-router-dom';   
import axios from 'axios';
import {  connect } from 'react-redux';
import ReactDOM from 'react-dom';
import FlashMessage from 'react-flash-message';
import Cart from './Cart';
export const removeItem = (id)=>{
    const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);  
    console.log(arrayproduct);
   var totalprice=0; var subtotal=0;
   var carthtml='<table><tbody>';
 
   if(arrayproduct.length == 1){
    localStorage.clear();
    //$('#cart-content').remove();
   }
  
    for (var i = 0; i < arrayproduct.length; i++) {
      //var index = arrayproduct.indexOf(arrayproduct[i].itemid);
      //alert(arrayproduct[i].itemid);alert(id);
     // alert(i);
    if (arrayproduct[i].itemid === id) {
     arrayproduct.splice(i, 1);
     // delete arrayproduct[i];
     // alert(id);
     //arrayproduct= arrayproduct.filter(item[i] => arrayproduct[i].itemid !== id)
      //arrayproduct.splice(i--,1);
      console.log(arrayproduct);
     // arrayproduct[i].qty=arrayproduct[i].qty+1;
    } 
}
   
  
  if(id){
    $('#'+id).remove();
    $('#cart-total').text(arrayproduct.length);
  }
  
  if(arrayproduct.length){
    localStorage.setItem("cart", JSON.stringify(arrayproduct));
    ReactDOM.render(<Cart/>, document.getElementById('cart-content'));
  }
  ReactDOM.render(<Cart/>, document.getElementById('cart-content'));
}
export const increaseQty = (id)=>{
    const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
    const arrayproduct =  JSON.parse(oldproduct);
    for (var i = 0; i < arrayproduct.length; i++) {
      if (arrayproduct[i].itemid === id) {
        
        arrayproduct[i].qty=arrayproduct[i].qty+1;
      } 
    }
    localStorage.setItem("cart", JSON.stringify(arrayproduct));
    ReactDOM.render(<Cart/>, document.getElementById('cart-content'));
}
export const decreaseQty = (id)=>{
  const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
  const arrayproduct =  JSON.parse(oldproduct);
  for (var i = 0; i < arrayproduct.length; i++) {
    if (arrayproduct[i].itemid === id) {
      if(arrayproduct[i].qty > 1){
        arrayproduct[i].qty=arrayproduct[i].qty-1;
      }
     
    } 
  }
  localStorage.setItem("cart", JSON.stringify(arrayproduct));
  ReactDOM.render(<Cart/>, document.getElementById('cart-content'));
}

  class Common extends Component { 
    constructor(props) {
        super(props);
        this.state={
            data: []
          
          }
     
    }
    
  
      handleClick(event) {
        const id = event.target.id;
        const name = event.target.title;
        const price =event.target.getAttribute('itemprice'); 
        const thumb = event.target.getAttribute('thumb');
        const pricenumber = Number(price.replace(/[^0-9.-]+/g,""));
       // const cartObj=[];
      console.log(event.target); 
        const cartObj = {
          itemid: id,
          pricestring:price,
          itemprice:pricenumber,
          itemname:name,
          itemthumb:thumb,
          qty: 1
      };
      var value = id;
      var found = false;
      const oldproduct = localStorage.getItem('cart') ? localStorage.getItem('cart') : "[]";
      const arrayproduct =  JSON.parse(oldproduct);  
     if(arrayproduct.length){
       $('#cart-total').text(arrayproduct.length);
     }
     var totalprice=0; var subtotal=0;
     var carthtml='<table><tbody>';
      for (var i = 0; i < arrayproduct.length; i++) {
        if (arrayproduct[i].itemid === id) {
          arrayproduct[i] = cartObj;
          found = true;
          arrayproduct[i].qty=arrayproduct[i].qty+1;
        } 
        totalprice +=arrayproduct[i].qty*arrayproduct[i].itemprice;
        //alert(arrayproduct[i].thumb)
        carthtml += `<tr class="product_row not_gift"  product_id='${arrayproduct[i].itemid}' parent_product_id="" product_row="0">
        <td class="image">
        <a href=""><img src="${arrayproduct[i].itemthumb}" alt="${arrayproduct[i].itemname}" title="${arrayproduct[i].name}"></a>
        </td>
        <td class="name"><a href="">${arrayproduct[i].itemname}<br>${arrayproduct[i].pricestring} x ${arrayproduct[i].qty}</a></td>
        <td class="quantity"><div class="quantity_wrap"><span class="stepper down">-</span><input type="text" name="order_product[0][quantity]" value="${arrayproduct[i].qty}" class="quantity" min="1"><span class="stepper up">+</span></div>
        <a class="btn_remove" onClick=${this.removehandleClick}></a>
        
        </td>
        </tr>`;
       
      }
      carthtml +='</tbody></table>';
      var carttotal=`<table><tbody><tr><td><b>Sub-Total:</b></td><td>$ ${totalprice}</td></tr>`;
      carttotal +=`<tr><td><b>Total:</b></td><td>$ ${totalprice}</td></tr>`;
      
      carttotal +=`</tbody></table>`;
      if (!found) {
        arrayproduct.push(cartObj);
      }
      this.setState({ showMessage: true });
      //Make div hide containing flash message
      setTimeout(
        () => this.setState({ showMessage: false }),
        5000
      );
     if(carthtml){
       $('.mini-cart-info').html(carthtml);
       $('.mini-cart-total').html(carttotal);
     }
     else{
      $('.mini-cart-info').html('');
     }
      if(!oldproduct){
        localStorage.setItem("cart", JSON.stringify(cartObj));
       }
       else{
        localStorage.setItem("cart", JSON.stringify(arrayproduct));
       }
      
      //localStorage.clear();
// getting back the object
    const copyCartObj = JSON.parse(localStorage.getItem("cart"));
     console.log(copyCartObj);
       // addToCart(id,1); 
      }
     
   
  

  }
 

export default Common;
//export default connect(mapStateToProps,mapDispatchToProps)(Category)